.main-wrapper {
  display: flex;
  margin-bottom: 20px;
  flex-wrap: wrap;
}

.sections label {
  margin-left: 10px;
  margin-right: 10px;
}

.AddConfigs {
  width: 90%;
  margin: 20px auto;
}

.viewConfigs {
  width: 90%;
  margin: 20px auto;
}

.validBades {
  width: 100%;
  margin: 10px auto;
  text-align: left;
  font-size: 0.7em;
}

/* Change color on hover */
.sections:hover {
  color: rgb(2, 172, 2);
}

.submitRequest {
  text-align: left;
  width: 350px;
  display: inline-block;
}

.name {
  margin-left: 10px;
  margin-right: 10px;
  font-size: large;
}

.desc {
  font-size: large;
}

.navbar {
  width: 100%;
  background: #152939;
  display: inline-block;
  padding: 0px;
  padding-top: 0rem; /* 0px does not look good on small screens */
  padding-bottom: 0rem;
}
.nav-item {
  color: white;
}

.navbar-brand {
  color: #fff;
  font-size: 1.2rem;
  font-family: Amazon Ember Light, Helvetica Neue, Arial, Helvetica, Roboto,
    sans-serif;
  font-weight: 900;
  padding: 5px 0px 2px 19px;
  /* margin-left: -510px; */
  /* margin-left:-1080px; */
  margin-left: -451px;
}

.navbar-user {
  color: #fff;
  font-size: 0.9rem;
  font-family: Amazon Ember, Helvetica Neue, Arial, Helvetica, Roboto,
    sans-serif;
  font-weight: 500;
  padding: 5px 0px 2px 19px;
}

.navbar-user:hover,
.navbar-brand:hover {
  color: rgb(4, 201, 250);
}

.navbar-icon {
  margin-right: 5%;
  text-align: center;
  width: 28px;
  padding: 5px 2px 9px 2px;
}
.search-box-container input {
  /* padding: 2px 15px 2px 45px; */
  white-space: nowrap;
  text-align: left;
  float: right;
}

.search-box {
  /* width: 150%; */
  /* padding: 5px -4px 3px 99px; */
  white-space: nowrap;
  /* left: 35px; */
  float: right;
}

.search-box-container::after {
  content: '\f002';
  font-family: FontAwesome;
  margin-left: -20px;
  margin-right: 1px;
  text-align: left;
}

.input-group.input-group-unstyled input.form-control {
  -webkit-border-radius: 10px;
  -moz-border-radius: 20px;
  border-radius: 10px;
}

.form-control1 {
	display: block;
	width: 95%;
	height: calc(1.5em + 0.75rem + 2px);
	padding: 0.01rem 0.75rem;
	font-size: 1rem;
	font-weight: 400;
	line-height: 1.5;
	color: #495057;
	background-color: #fff;
	background-clip: padding-box;
	border: 1px solid #ced4da;
	border-radius: 0.25rem;
	transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    }
.input-group-unstyled .input-group-addon {
  border-radius: 4px;
  border: 0px;
  background-color: transparent;
}
.panel {
  width: 500px;
}

.panel-heading {
  min-height: 36px;
}

.listOfValues {
  text-align: right;
}
.custom-control {
  margin-right: 3%;
  text-align: right;
  /* width: 79pc; */
  position: relative;
}

.button {
  margin-right: 0px;
}

.submit {
  margin-right: -85px;
  display: inline-block;
}

.modal-popup {
  text-align: right;
}

/* CSS for ON OFF */

.switch {
  position: relative;
  display: inline-block;
  width: 80px;
  height: 34px;
}

.switch input {
  display: none;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ca2222;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

.slider:before {
  position: absolute;
  content: '';
  height: 26px;
  width: 26px;
  left: 4px;
  bottom: 4px;
  background-color: white;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

input:checked + .slider {
  background-color: #2ab934;
}

input:focus + .slider {
  box-shadow: 0 0 1px #2196f3;
}

input:checked + .slider:before {
  -webkit-transform: translateX(55px);
  -ms-transform: translateX(55px);
  transform: translateX(55px);
}

/*------ ADDED CSS ---------*/
.on {
  display: none;
}

.on,
.off {
  color: white;
  position: absolute;
  transform: translate(-50%, -50%);
  top: 50%;
  left: 50%;
  font-size: 10px;
  font-family: Verdana, sans-serif;
}

input:checked + .slider .on {
  display: block;
}

input:checked + .slider .off {
  display: none;
}

/*--------- END --------*/

/* Rounded sliders */
.slider.round {
  border-radius: 34px;
}

.slider.round:before {
  border-radius: 50%;
}

.input-group {
  width: 80%;
  margin: 25px;
}

p {
  margin-top: 10px;
  margin-bottom: 10px;
  margin-right: 10px;
  margin-left: 100px;
}

th {
  /* background: #152938; */
  /* background: #558a91; */
  background: #152939;
  color: white;
  text-align: 'center';
  white-space: nowrap;
  font-size: 0.9rem;
  font-family: Amazon Ember Light, Helvetica Neue, Arial, Helvetica, Roboto,
    sans-serif;
  font-weight: bold;
}

/* th:hover {
  color: #000000;
} */

td:hover {
  color: #558a91;
}

td {
  word-wrap: break-word;
  text-align: 'center';
  /* white-space: nowrap; */
  font-size: 0.8rem;
  font-family: Amazon Ember Light, Helvetica Neue, Arial, Helvetica, Roboto,
    sans-serif;
  font-weight: bold;
}

right-align {
  text-align: 'right';
  white-space: nowrap;
}

.pull-right {
  float: right;
}

.link-align {
  display: inline;
  margin-right: 1em;
  font-weight: bold;
}

.view-styling {
  color: rgb(4, 201, 250);
}

.edit-styling {
  /* color:rgb(216, 203, 25); */
  color: #333;
}
.disable-styling {
  /* color:rgb(189, 50, 50); */
  /* color: #FFA07a; */
  color: #0e165a;
}

.enable-styling {
  /* color:rgb(78, 189, 50); */
  color: #9acd32;
  font-weight: 250;
}

.remove-styling {
  /* color:rgb(78, 189, 50); */
  color: #f08080;
  font-weight: 250;
}

.button-styling {
  background: none;
  border: none;
  font-weight: bold;
}
.button-styling:hover {
  color: #1038bd;
}

h3 {
  font-family: Amazon Ember Light, Helvetica Neue, Arial, Helvetica, Roboto,
    sans-serif;
  font-weight: bold;
  color: #152939;
  font-size: 1.2em;
}

h2 {
  font-family: Amazon Ember Light, Helvetica Neue, Arial, Helvetica, Roboto,
    sans-serif;
  font-weight: bold;
  font-size: 1em;
  color: #152939;
  /* color: #333; */
}

h3:hover {
  color: rgb(49, 4, 250);
}

h2:hover {
  color: rgb(8, 4, 250);
}

h8 {
  text-transform: uppercase;
  font-weight: bold;
}

li input {
  width: 10px;
  position: relative;
  left: 0px;
  vertical-align: middle;
}

label {
  width: 200px;
  position: relative;
  left: 0px;
  margin: 0px;
  vertical-align: middle;
}
li div {
  display: table-cell;
  vertical-align: middle;
  list-style-type: none;
}

ul {
  list-style-type: none;
}

.group {
  margin-bottom: 20px;
}
.group label {
  font-size: 12px;
}

p {
  font-size: 13px;
}
.textSize {
  font-size: 11px;
  /* font-weight: bold; */
}

.textSize:hover {
  color: #558a91;
}
.textSizeHeader {
  font-size: 15px;
  font-weight: bold;
}
.textSizeHeader:hover {
  color: #558a91;
}
.textSizeSemiHeader {
  font-size: 12px;
  font-weight: bold;
}
.textSizeSemiHeader:hover {
  color: #558a91;
}

.centerAlign {
  text-align: center;
}

.textSizeMegaHeader {
  font-size: 20px;
  font-weight: bold;
  color: #558a91;
}
.textSizeMegaHeader:hover {
  color: rgb(4, 201, 250);
}

.headerInline {
  display: inline;
}

.headerMargin {
  float: right;
}

.headerMarginLeft {
  float: left;
}

.addNewStyling {
  /* color: rgb(4, 201, 250); */
  color: #008080;
}

.upperCaseFont {
  text-transform: uppercase;
}

.wrapContent {
  overflow-x: scroll;
  word-wrap: break-word;
}

.checkboxSpacing {
  margin-bottom: 7px;
}

.validBades:last-child {
  margin-bottom: none;
}

.hrStyling {
  width: auto;
  margin: 100px -15px -80px;
  border: 0;
  border-top: 2px solid #c9c7c7;
}

.hrStylingBadges {
  width: auto;
  border: 0;
  border-top: 2px solid #c9c7c7;
}

.searchBarstyle {
  position: relative;
}

input[type='checkbox'][readonly] {
  pointer-events: none !important;
}

table.breaklines {
  width: 100%;
}
.breaklines {
  word-wrap: break-word;
  table-layout: fixed;
}

thead,
tbody,
tr {
  display: table;
  width: 100%;
  table-layout: fixed;
}
td {
  word-wrap: break-word;
}

tbody {
  display: block;
  overflow: auto;
  table-layout: fixed;
  max-height: 550px;
}

th {
  /* background: #152938; */
  /* background: #558a91; */
  background: #152939;
  color: white;
  text-align: 'center';
  /* white-space: nowrap; */
  font-size: 0.9rem;
  font-family: Amazon Ember Light, Helvetica Neue, Arial, Helvetica, Roboto,
    sans-serif;
  font-weight: bold;
}

th:hover {
  color: #000000;
}

td:hover {
  color: #558a91;
}

td {
  text-align: 'center';
  /* white-space: nowrap; */
  font-size: 0.8rem;
  font-family: Amazon Ember Light, Helvetica Neue, Arial, Helvetica, Roboto,
    sans-serif;
  font-weight: bold;
}

:root {
  --bg: whitesmoke;
  --color: black;
}

p {
  font-family: Amazon Ember Light, Helvetica Neue, Arial, Helvetica, Roboto,
    sans-serif;
  font-weight: bold;
  font-size: 0.8em;
  color: #152939;
  margin-top: 10px;
  margin-bottom: 0px;
  margin-right: 10px;
  margin-left: 100px;
}

p:hover {
  color: rgb(8, 4, 250);
}

#main {
  width: 500px;
  height: 200px;
  border: 1px soli;
  display: flex;
  flex-wrap: wrap;

  text-align: center;
}

/*-------------------*/
element.style {
  position: relative;
}

.card {
  position: relative;
  height: auto;
  margin: 0 auto;
  float: none;
  margin-bottom: 10px;
  text-align: left;
  display: flex;
}

.card-2 {
  position: relative;
  height: auto;
  margin: 0 auto;
  float: none;
  margin-bottom: 10px;
  text-align: left;
  display: flex;
}

.row {
  display: flex;
  flex-wrap: wrap;
  margin-right: -15px;
  margin-left: -15px;
  padding-left: 10pc;
}

.row1 {
  display: flex;
  flex-wrap: wrap;
  margin-right: -15px;
  margin-left: -15px;
  padding-left: 1pc;
}

.card1 {
  position: relative;
  height: auto;
  margin: 0 auto;
  padding: 40px 20px;
  transition: 0.5s;
  transform: scale(1);
  box-shadow: 0 0 4px 1px rgba(20, 20, 20, 0.8);
  text-align: center;
}

.col {
  align-items: center;
  flex-basis: 0;
  flex-grow: 1;
  max-width: 37%;
  padding-left: 1pc;
  padding-right: 0pc;
}
.col1 {
  flex-basis: 0;
  flex-grow: 1;
  max-width: 50%;
  padding-left: 1pc;
}

.col2 {
  align-items: left;
  flex-basis: 0;
  flex-grow: 1;
  max-width: 37%;
  padding-left: 1pc;
  padding-right: 0pc;
}
.col3 {
  align-items: right;
  flex-basis: 0;
  flex-grow: 1;
  max-width: 37%;
  padding-left: 1pc;
  padding-right: 0pc;
}

*,
*::before,
*::after {
  box-sizing: revert;
}

/*-------------------*/
.chart {
  overflow-x: auto;
  position: relative;
  width: 100%;
}
/* .scroll-horizental-histo {
  overflow-x: scroll;
} */

body {
  margin: 40px;
}

.card-img,
.card-img-top,
.card-img-bottom {
  flex-shrink: 0;
  /* width: 25%; */
  border-radius: 100px;
  /* position: absolute; */
  /* top: 11;
  right: 15; */
  border-radius: 50%;
  height: 100%;
  width: 12%;
}

#parent {
  width: 80%;
  height: 100%;
  text-align: center; /* this is the key */
  position: relative;
  margin: 0 auto;
}

/* .card {
  width: 100px;
  height: 100px;
  display: inline-block;
  line-height: 100px;
  font-weight: bold;
  font-size: 36px;
  } */

.Center {
  /* width: 300px;
  height: 200px; */
  position: relative;
  top: 45%;
  left: 40%;
}

.modal-new {
  left: -320px;
  -webkit-transition: opacity 0.3s linear, left 0.3s ease-out;
  -moz-transition: opacity 0.3s linear, left 0.3s ease-out;
  -o-transition: opacity 0.3s linear, left 0.3s ease-out;
  transition: opacity 0.3s linear, left 0.3s ease-out;
}

/*----  Main Style  ----*/
#cards_landscape_wrap-2 {
  text-align: center;
  background: #f7f7f7;
}
#cards_landscape_wrap-2 .container {
  /* padding-top: 80px;  */
  padding-bottom: 100px;
}
#cards_landscape_wrap-2 a {
  text-decoration: none;
  outline: none;
}
#cards_landscape_wrap-2 .card-flyer {
  border-radius: 5px;
}
#cards_landscape_wrap-2 .card-flyer .image-box {
  background: #ffffff;
  overflow: hidden;
  box-shadow: 0px 2px 15px rgba(0, 0, 0, 0.5);
  /* border-radius: 1px; */
}
#cards_landscape_wrap-2 .card-flyer:hover .image-box img {
  opacity: 0.7;
  -webkit-transform: scale(1.15);
  -moz-transform: scale(1.15);
  -ms-transform: scale(1.15);
  -o-transform: scale(1.15);
  transform: scale(1.15);
}
#cards_landscape_wrap-2 .card-flyer .text-box {
  text-align: center;
}
#cards_landscape_wrap-2 .card-flyer .text-box .text-container {
  padding: 30px 18px;
}
#cards_landscape_wrap-2 .card-flyer {
  background: #ffffff;
  margin-top: 50px;
  -webkit-transition: all 0.2s ease-in;
  -moz-transition: all 0.2s ease-in;
  -ms-transition: all 0.2s ease-in;
  -o-transition: all 0.2s ease-in;
  transition: all 0.2s ease-in;
  box-shadow: 0px 3px 4px rgba(0, 0, 0, 0.4);
}
#cards_landscape_wrap-2 .card-flyer:hover {
  background: #fff;
  box-shadow: 0px 15px 26px rgba(0, 0, 0, 0.5);
  -webkit-transition: all 0.2s ease-in;
  -moz-transition: all 0.2s ease-in;
  -ms-transition: all 0.2s ease-in;
  -o-transition: all 0.2s ease-in;
  transition: all 0.2s ease-in;
  margin-top: 50px;
}
#cards_landscape_wrap-2 .card-flyer .text-box p {
  margin-top: 10px;
  margin-bottom: 0px;
  padding-bottom: 0px;
  font-size: 14px;
  letter-spacing: 1px;
  color: #000000;
}
#cards_landscape_wrap-2 .card-flyer .text-box h6 {
  margin-top: 0px;
  margin-bottom: 4px;
  font-size: 18px;
  font-weight: bold;
  text-transform: uppercase;
  font-family: 'Roboto Black', sans-serif;
  letter-spacing: 1px;
  color: #00acc1;
}

.card1:hover {
  -webkit-transform: scale(1.1);
  transform: scale(1.1);
}

.wrapper {
  position: relative;
  background: #141e30; /* fallback for old browsers */
  background: -webkit-linear-gradient(
    to right,
    #243b55,
    #141e30
  ); /* Chrome 10-25, Safari 5.1-6 */
  background: linear-gradient(
    to right,
    #243b55,
    #141e30
  ); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */

  background-size: cover;
  overflow: hidden;
}

.my-modal {
  width: 90vw; /* Occupy the 90% of the screen width */
  max-width: 90vw;
  /* background-color: #525f7f; */
}
/* .modal-body {
  background-color: #5e72e4;
} */
